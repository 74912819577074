<footer class="wow fadeInUp">
  <div class="container footer-row1">
    <div class="row">
      <!--
                <div class="col-md-3">
                    <h3 class="footer-h3">our thoughts</h3>
                    <ul class="footer-ul">
                        <li>
                            <a href="#">
                                E Banks That Accept Us Casino Players<br>
                                <span>24 Apr 2018</span>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                Little Things Do Make A Difference<br>
                                <span>19 Oct 2018</span>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                Guidelines For Inkjet Cartridge Refill<br>
                                <span>24 Apr 2018</span>
                            </a>
                        </li>
                    </ul>
                </div>
                -->
      <div class="col-md-3">
        <h3 class="footer-h3">Contact Us</h3>
        <ul class="footer-ul">
          <li>
          
            <span style="color: #a1a8ae;">Phone number</span><br />
              +1-858-848-6782<br />
              +91-939-466-6269
            
          </li>
          <li>
            <a href="mailto:info@innobox.com">
              <span>Email</span><br />
              info@innobox.com
            </a>
          </li>
          <li>
            
            <span style="color: #a1a8ae;">Business hours</span><br />
              Monday - Friday 0900 IST - 1800 IST
         
         
        </ul>
      </div>
     

      <div class="col-md-3">
        <h3 class="footer-h3">USEFUL LINKS</h3>
        <ul class="footer-ul">
          <li>
            <a routerLink="/{{ appConfig.routes.aboutus }}"> About Us </a>
          </li>
          <li>
            <a routerLink="/{{ appConfig.routes.mainservice }}"> Services </a>
          </li>
          <li>
            <a routerLink="/{{ appConfig.routes.successstory }}">
              Success Stories
            </a>
          </li>
          <li>
            <a routerLink="/{{ appConfig.routes.whyus }}"> Why Us? </a>
          </li>
          <li>
            <a routerLink="/{{ appConfig.routes.career }}"> Careers </a>
          </li>
          <li>
            <a routerLink="/{{ appConfig.routes.contactus }}"> Contact Us </a>
          </li>
        </ul>
      </div>
      <div class="col-md-3">
        <h3 class="footer-h3">Social Media</h3> 
        <ul class="footer-ul">
          <li *ngFor="let item of social; let i = index">
        <a *ngIf="item.status == 0 && item.value!='' && item.title=='facebook'" href="{{item.value}}" target="_blank" class="fa fa-facebook"></a>
        <a *ngIf="item.status == 0 && item.value!='' && item.title=='twitter'" href="{{item.value}}" target="_blank" class="fa fa-twitter"></a>
        <a *ngIf="item.status == 0 && item.value!='' && item.title=='linkdin'" href="{{item.value}}" target="_blank" class="fa fa-linkedin"></a>
        <a *ngIf="item.status == 0 && item.value!='' && item.title=='insta'" href="{{item.value}}" target="_blank" class="fa fa-instagram"></a></li></ul>
      </div>
      <div class="col-md-3">
        <h3 class="footer-h3">information</h3>
        <ul class="footer-ul">
          <li>
            <a routerLink="/{{ appConfig.routes.terms }}">
              Privacy Policy
            </a>
          </li>
          <li>
            <a routerLink="/{{ appConfig.routes.legal }}"> Legal </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="footer-row2">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          © {{currentYear}} - All Rights Reserved Innobox Pvt ltd 
        </div>
      </div>
    </div>
  </div>
</footer>
