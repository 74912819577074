<nav class="navbar navbar-expand-lg navbar-dark fixed-top wow fadeInDown" id="mainNav">
   <div class="container">
      <a class="navbar-brand js-scroll-trigger" routerLink="{{ appConfig.routes.home }}">
         <img src="../../../assets/img/innoboxlogo.svg" alt="innobox logo" class="gbitlogo" />
      </a>
     
       <button class="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                    <div class="container-hamburg" id="hamburg" onclick="myFunction(this)">
                        <div class="bar1"></div>
                        <div class="bar2"></div>
                        <div class="bar3"></div>
                    </div>
                    <i class="fa fa-bars"></i>
            </button>
      <div class="collapse navbar-collapse" id="navbarResponsive">

         <ul class="ml-auto navbar-nav">
            <div *ngFor="let item of menu; let i = index">
               <li *ngIf="item.children.length == 0" class="nav-item " style="padding: 10px;">
                  <a class="nav-link js-scroll-trigger" routerLink="{{item.href}}">{{item.itemName}}</a>
               </li>

               <li *ngIf="item.children.length > 0" class="nav-item dropdown" style="padding: 10px;">
                  <a class="nav-link dropdown-toggle pointer" role="button" data-toggle="dropdown"
                     aria-haspopup="true" aria-expanded="false">{{item.itemName}}</a>

                  <div class="dropdown-menu"
                     aria-labelledby="navbarDropdown">
                   
                        <!--<div class="row"><div class="up-arrow"></div></div>-->

                        <div *ngFor="let sub of item.children; let i = index" class="childs">
                           <ul class="nav flex-column">
                              <li *ngIf="sub.children.length == 0 && sub.itemName != 'Automotive'" style="border-bottom: 1px  solid #f0f0f0;"  class="nav-item">
                                 <a class="nav-link js-scroll-trigger" routerLink="service/{{sub.itemName}}/{{sub.ID}}">{{sub.itemName}}</a>
                              </li>
                              <li *ngIf="sub.children.length == 0 && sub.itemName == 'Automotive'" style="border-bottom: 1px  solid #f0f0f0;"  class="nav-item">
                                 <a class="nav-link js-scroll-trigger" href="http://automotive.innobox.com">{{sub.itemName}}</a>
                              </li>
                              <li *ngIf="sub.children.length > 0" style="border-bottom: 1px  solid #f0f0f0;"  class="nav-item dropdown testt">
                                 <a class="nav-link  pointer" 
                                    >{{sub.itemName}}</a>
                                    <div class="dropdown-menu-sub">
                                     <div *ngFor="let subchild of sub.children; let i = index">
                                          <ul class="nav flex-column">
                                             <li  class="nav-item" style="border-bottom: 1px  solid #f0f0f0;">
                                                <a class="nav-link js-scroll-trigger" routerLink="service/{{subchild.itemName}}/{{subchild.ID}}">{{subchild.itemName}}</a>
                                             </li>
                                             
                                          </ul>
                                       </div>
                                  
                                 </div> 
                              </li>
                           </ul>
                        </div>
                   
                  </div> 
               </li>
            </div>
         </ul>

      </div>
   </div>
</nav>