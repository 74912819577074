<div class="modal" id="myModalsevices">
  <div class="modal-dialog">
    <div class="modal-content">
      <!-- Modal Header
             <div class="modal-header">
             <h4 class="modal-title"></h4>

             </div>
             -->
      <!-- Modal body -->
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12">
            <button
              id="dismiss"
              type="button"
              class="close"
              data-dismiss="modal"
            >
              &times;
            </button>
            <div
              class="
                innobox-contactfrmcontainer innobox-contactfrmcontainer-right
              "
            >
              <form
                class="form-contact innoboxcontact-form addcountry6"
                id="innoboxcontact-form-services"
                [formGroup]="contactForm"
                (ngSubmit)="addData()"
              >
                <div class="form-group row">
                  <div class="col-md-6">
                    <label for="name-services">NAME</label>
                    <input
                      class="form-control"
                      name="name"
                      formControlName="name"
                      id="name-services"
                      type="text"
                    />
                    <P
                      class="error-class contact-error-class"
                      *ngIf="
                        contactForm.get('name')?.touched &&
                        contactForm.get('name')?.errors
                      "
                    >
                      Name is Required
                    </P>
                  </div>
                  <div class="col-md-6">
                    <label for="email-services">E-MAIL</label>
                    <input
                      class="form-control"
                      name="email"
                      formControlName="email"
                      id="email-services"
                      type="email"
                    />
                    <P
                      class="error-class contact-error-class"
                      *ngIf="
                        contactForm.get('email')?.touched &&
                        contactForm.get('email')?.errors
                      "
                    >
                      Email is Required
                    </P>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-md-6">
                    <label for="company-services">COMPANY</label>
                    <input
                      class="form-control"
                      name="company"
                      formControlName="company"
                      id="company-services"
                      type="text"
                    />
                    <P
                      class="error-class contact-error-class"
                      *ngIf="
                        contactForm.get('company')?.touched &&
                        contactForm.get('company')?.errors
                      "
                    >
                      Company is Required
                    </P>
                  </div>
                  <div class="col-md-6">
                    <label for="mobilenumber-services">PHONE NUMBER</label>
                    <input
                      class="form-control"
                      formControlName="phone"
                      name="mobilenumber"
                      id="mobilenumber-services"
                      type="number"
                      min="1"
                    />
                    <div *ngIf=" f.phone.touched &&
                    f.phone.errors" class="error-class contact-error-class">  
                      <div *ngIf="f.phone.errors.required">Mobile Number is required.</div>  
                     <div *ngIf="f.phone.errors.pattern">Please, Enter 10 digit Mobile Number.</div>  
                  </div> 
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-md-12">
                    <label for="messageabout">What is your message about</label>
                    <select
                      formControlName="messagecat"
                      class="form-control"
                      id="messageabout"
                      name="job2"
                    >
                      <option value="0">Select</option>
                      <option value="1">Sales</option>
                      <option value="2">Support</option>
                    </select>
                    <P
                      class="error-class contact-error-class"
                      *ngIf="
                        contactForm.get('messagecat')?.touched &&
                        contactForm.get('messagecat')?.errors
                      "
                    >
                      Category is Required
                    </P>
                  </div>
                </div>

                <div class="form-group row">
                  <div class="col-md-12">
                    <label for="message-services">Message</label>
                    <textarea
                      class="form-control"
                      formControlName="message"
                      name="message"
                      rows="5"
                      id="message-services"
                    ></textarea>
                    <P
                      class="error-class contact-error-class"
                      *ngIf="
                        contactForm.get('message')?.touched &&
                        contactForm.get('message')?.errors
                      "
                    >
                      Message is Required
                    </P>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12 text-center">
                    <button type="submit" class="btn btn-warning innoboxsubmit">
                      Send Message
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <!-- Modal footer
             <div class="modal-footer">
             <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
             </div>
             -->
    </div>
  </div>
</div>
<p class="internal-headersecp1">
  <a
    href="#"
    class="internal-headersecp1a"
    data-toggle="modal"
    data-target="#myModalsevices"
    >Talk to Our Expert</a
  >
</p>
<div class="modal formsucess_modalsucess" [ngStyle]="{'display':display}" id="formsucess">
  <div class="modal-dialog">
    <div class="modal-content">
      <!-- Modal body -->
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12">
            <button type="button" class="close" (click)=closemodal()>
              &times;
            </button>
            <svg
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 130.2 130.2"
            >
              <circle
                class="path circle"
                fill="none"
                stroke="#73AF55"
                stroke-width="6"
                stroke-miterlimit="10"
                cx="65.1"
                cy="65.1"
                r="62.1"
              />
              <polyline
                class="path check"
                fill="none"
                stroke="#73AF55"
                stroke-width="6"
                stroke-linecap="round"
                stroke-miterlimit="10"
                points="100.2,40.2 51.5,88.8 29.8,67.5 "
              />
            </svg>
            <p>
              Thank you!<br />
              We received your information. Our expert team will get back to you
              soon.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>