<!--  -->
<!-- internalheader-start -->
<section class="internal-headersec-contectivity wow fadeInUp"  *ngFor="let items of res"  [ngStyle]="{background: 'url('+appConfig.imageUrl + items.image1+ ')no-repeat'}">
    
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h2 class="internal-headersech2"> {{items.title}}<br /></h2>
                <p class="internal-headersecp">{{items.qtxt}}</p>
                <p class="internal-headersecp1">
                    <app-talktoourexpert></app-talktoourexpert>
                </p>
            </div>
        </div>
    </div>
</section>
<!-- internalheader-end -->
<!-- service - details - start -->
<section class="servicedetails-sec wow fadeInUp" *ngFor="let items of res">
    <div class="container">
        <div class="row">
            <div class="col-md-2 "></div>
            <div class="col-md-7 ">
                <p class="servicedetails-p" [innerHTML]="items.shdcr"></p>
               
            </div>
            <div class="col-md-3"></div>
        </div>
    </div>
</section>
<!-- service - details - end -->
<!-- why innobox - start -->
<section class="whyinnoboxservice-sec" *ngFor="let items of res">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-9 wow fadeInLeft">
                <div class="whyinnoboxdiv1">
                    <h2 class="whyinnoboxdiv1-h2">{{items.title}}</h2>
                    <p class="whyinnoboxdiv1-p1" [innerHTML]="items.fdcr"></p>
                </div>

                
                <div  class="whyinnoboxdiv2"   [ngStyle]="{background: 'url('+appConfig.imageUrl + items.image2+ ')'}"> 
      
       
                </div>
            </div>
            <div class="col-md-3 wow fadeInRight">
                <h3 class="highlights-h3">Highlights</h3>

                <div class="highlights-li"  *ngFor="let values of highlightValues;let i = index">
                    <h3 class="highlights-lih3">0{{i+1}}</h3>
                    <p class="highlights-lip1">
                        {{values.cntnt}}
                    </p>
                    <!-- <p class="highlights-lip2">03 Jun 2018</p>-->
                </div>
            </div>
        </div>
    </div>
    
</section> 


<!-- why innobox - end -->
<!-- our other services - start -->

<!-- our other services - end -->
<section class="otherservices-sec wow fadeInUp">
    <div class="container">
        <div class="row">
            <div class="col-md-12" >
                <h3 class="otherservices-h3">Our Other Services</h3>
                <div class="otherservices-contextbox">
                    <div class="row">
                        <div class="col-md-4"  *ngFor="let items of otherServices">
                            <a href="/#/service/{{items.title}}/{{items.serviceID}}">
                                <div class="otherservices-box">
                                    <img [src]="appConfig.imageUrl + items.image2" style="min-width:280px;min-height:184px;max-width: 280px; max-height: 184px;" alt="other services"
                                        class="otherservices-img" />
                                    <div class="otherservices-boxdiv">
                                        <h3 class="otherservices-boxdivh3">{{items.title}}</h3>
                                        <p class="otherservices-boxdivp" [innerHTML]="items.shdcr"></p>
                                       
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


                  
<!-- our other services - end -->
<section class="experience-sec wow fadeInUp">
    <div class="container">
        <div class="row">
            <div class="col-md-12" *ngFor="let items of res" >
                <h2 class="experience-h2">Have you tried innobox?</h2>
                <p class="experience-p" [innerHTML]="items.havetried"></p>
              
                <p class="experience-p1">
                    <a href="#" class="" data-toggle="modal" data-target="#myModalsevices">Get In Touch</a>
                </p>
            </div>
        </div>
    </div>
</section>
<!-- <app-commonsection></app-commonsection
  > -->
<!-- The Modal -->
<div class="modal" id="myModalsevices">
    <div class="modal-dialog">
        <div class="modal-content">
            <!-- Modal Header
               <div class="modal-header">
               <h4 class="modal-title"></h4>
  
               </div>
               -->
            <!-- Modal body -->
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-12">
                        <button type="button" class="close" data-dismiss="modal">
                            &times;
                        </button>
                        <div class="
                  innobox-contactfrmcontainer innobox-contactfrmcontainer-right
                ">
                            <form class="form-contact innoboxcontact-form addcountry6"
                                id="innoboxcontact-form-services">
                                <div class="form-group row">
                                    <div class="col-md-6">
                                        <label for="name-services">NAME</label>
                                        <input class="form-control" name="name" id="name-services" type="text" />
                                        <input name="latestworks" type="hidden" value="Latest Works-Ask for Quote" />
                                    </div>
                                    <div class="col-md-6">
                                        <label for="email-services">E-MAIL</label>
                                        <input class="form-control" name="email" id="email-services" type="email" />
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-md-6">
                                        <label for="company-services">COMPANY</label>
                                        <input class="form-control" name="company" id="company-services" type="text" />
                                    </div>
                                    <div class="col-md-6">
                                        <label for="mobilenumber-services">PHONE NUMBER</label>
                                        <input class="form-control" name="mobilenumber" id="mobilenumber-services"
                                            type="number" min="1" />
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-md-12">
                                        <label for="messageabout-services">What is your message about</label>
                                        <select class="form-control" id="messageabout-services" name="job2">
                                            <option value="0">Select</option>
                                            <option>Sales</option>
                                            <option>Support</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <div class="col-md-12">
                                        <label for="message-services">Message</label>
                                        <textarea class="form-control" name="message" rows="5"
                                            id="message-services"></textarea>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-12 text-center">
                                        <button type="submit" class="btn btn-warning innoboxsubmit">
                                            Send Message
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Modal footer
               <div class="modal-footer">
               <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
               </div>
               -->
        </div>
    </div>
</div>

<!-- The Modal -->
<div class="modal formsucess_modalsucess" id="formsucess">
    <div class="modal-dialog">
        <div class="modal-content">
            <!-- Modal body -->
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-12">
                        <button type="button" class="close" data-dismiss="modal">
                            &times;
                        </button>
                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                            <circle class="path circle" fill="none" stroke="#73AF55" stroke-width="6"
                                stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1" />
                            <polyline class="path check" fill="none" stroke="#73AF55" stroke-width="6"
                                stroke-linecap="round" stroke-miterlimit="10"
                                points="100.2,40.2 51.5,88.8 29.8,67.5 " />
                        </svg>
                        <p>
                            Thank you!<br />
                            We received your information. Our expert team will get back to you
                            soon.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- The Modal -->
<div class="modal" id="formfailure">
    <div class="modal-dialog">
        <div class="modal-content">
            <!-- Modal body -->
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-12">
                        <button type="button" class="close" data-dismiss="modal">
                            &times;
                        </button>
                        <p>
                            Sorry!<br />
                            Something went wrong
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>